/* eslint-disable import/namespace */
import * as Sentry from '@sentry/nuxt';

const config = useRuntimeConfig();

Sentry.init({
	enabled: ['production', 'staging'].includes(
		String(config.public.appEnv) ?? '',
	),
	environment: String(config.public.appEnv),
	dsn: 'https://becf4f063e5f6f7e8b4e5d3b978b9ebf@o4507899822800896.ingest.de.sentry.io/4507899844034640',

	// We recommend adjusting this value in production, or using tracesSampler
	tracesSampleRate: 1, //  Capture 100% of the transactions

	// Session Replay
	replaysOnErrorSampleRate: 1.0,

	integrations: [
		Sentry.replayIntegration({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
});
